import React, { useState, useEffect } from "react";
import { Fragment } from "react";

const ImageLoader = (props) => {
  let {
    height = 100,
    width = 100,
    lazy = true,
    src,
    alt,
    title,
    styleGroup,
    stylePicture,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const isValidImage = (file) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.webp)$/i;
    return allowedExtensions.test(file.name);
  };

  useEffect(() => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      setIsLoading(false);
    };
    image.onerror = () => {
      setIsLoading(false);
    };
  }, [src]);

  return (
    <>
      {isLoading ? (
        <div style={{ height: {height}, width: {width} }}></div>
      ) : (
        <>
          <picture className={`w-100 ${stylePicture ? stylePicture : ""}`}>
            <source srcSet={`${src}?format=webp`} type="image/webp" />
            <source srcSet={`${src}?format=jpg`} type="image/jpeg" />
            <img
              className={`h_auto_img ${styleGroup ? styleGroup : ""}`}
              src={src}
              alt={alt?? "Prodesa image"}
              height={height}
              width={width}
              title={title}
              loading={lazy ? "lazy" : "eager"}
            />
          </picture>
        </>
      )}
    </>
  );
};

export default ImageLoader;
