'use client';
import ClientWrapper from "@/ClienteWrapper";
import ContactFloatingButton from "@ui/general/contactFloatingButton/ContactFloatingButton";
import { ScrollToTopButtonComponent } from "@components/ScrollToTopButtonComponent";

export default function Others() {
    return (
        <ClientWrapper>
            <ScrollToTopButtonComponent />
            <ContactFloatingButton />
        </ClientWrapper>
    );
}