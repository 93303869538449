import React, { Fragment } from "react";
import { isIOS } from "react-device-detect";
import ImageLoader from "@ui/general/ImageLoaderComponent";

function Picture({
  height,
  width,
  lazy,
  src,
  dummy,
  styleGroup,
  stylePicture,
  alt,
}) {
  let iOSVersion = null;

  if (isIOS) {
    const iOSVersionMatch = window.navigator.appVersion.match(
      /OS (\d+)_(\d+)_?(\d+)?/
    );
    if (iOSVersionMatch) {
      iOSVersion = parseInt(iOSVersionMatch[1], 10);
    }
  }

  const validationRegex = /undefined$/;
  if (src && typeof src === "string") {
    const errs = src.match(validationRegex);
    if (errs) return;
  }
  return (
    <Fragment>
      {iOSVersion > 13 || !isIOS ? (
        <ImageLoader
          src={src}
          styleGroup={styleGroup}
          stylePicture={stylePicture}
          alt={alt}
          dummy={dummy}
          height={height}
          width={width}
          lazy={lazy}
        />
      ) : (
        <picture>
          <source srcSet={src} media="(min-width: 360px)" />
          <img
            className={`w-100 h_auto_img ${styleGroup}`}
            src={src}
            alt={alt ?? "Prodesa"}
            loading={lazy ? "lazy" : "eager"}
            height={height}
            width={width}
          />
        </picture>
      )}
    </Fragment>
  );
}

export default Picture;
