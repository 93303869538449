'use client';

import style from "@scss/BtnChat.module.scss";
import { useApi } from "@context/ApiContext";
import { url_image } from "@api/ProyectoApi";
import Picture from "@ui/general/PictureComponent";
import styles from "@ui/general/contactFloatingButton/styles.module.css"

export default function ButtonItem({ item, onClick = () => {} }) {
  const { getTranslation } = useApi();

  return (
    <div
      className="d-flex align-items-center justify-content-end mb-3"
      onClick={onClick}
    >
      <p className={`${style.fzNombre} mb-0 WorkSans me-3 color-white`}>
        {getTranslation(item, "title")}
      </p>
      <div className={`${style.containerImg}`}>
        <Picture
          src={url_image + item.image.image}
          alt="Prodesa"
          styleGroup={`${styles.img_icon} w-auto`}
          height={35}
          width={35}
        />
      </div>
    </div>
  );
}